import {
  DAILY_TIMETABLE_WIDGET_ID,
  BOOK_BUTTON_WIDGET_ID,
  STAFF_LIST_WIDGET_ID,
} from '@wix/bookings-app-builder-controllers/dist/src/platform/platform.const';

export const MEMBERS_AREA_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const BOOKINGS_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';
export const MY_WALLET_DEF_ID = '4aebd0cb-fbdb-4da7-b5d1-d05660a30172';
export const PRICING_PLANS_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';

export enum WidgetsId {
  BOOKINGS_LIST = '621bc837-5943-4c76-a7ce-a0e38185301f',
}

export enum PageId {
  BOOKINGS_LIST = 'bookings_list',
  BOOKINGS_CHECKOUT = 'book_checkout',
  SCHEDULER = 'scheduler',
  BOOKINGS_MEMBERS_AREA = 'bookings_member_area',
  MY_WALLET = 'my_wallet',
  BOOKINGS_SERVICE_PAGE = 'Booking Service Page',
  BOOKINGS_BOOKING_PAGE = 'Booking Calendar',
}

export enum MigrationFailReason {
  STYLES = 'styles',
  PAGES = 'pages',
  DATA = 'data',
}

export const experiments = {
  BOOKINGS_MANAGE_MY_APP: 'specs.bookingsViewerScript.BookingsManageMyAppPanel',
  UOU_MULTI_LOCATION_V1: 'specs.bookings.UoUMultiLocationV1',
  OS_MULTI_LOCATION_ENABLED: 'specs.wossm.EnableMultiLocation',
  CONCURRENT_EDITING_ENABLED: 'specs.bookings.BookingsConcurrentEditing',
  PRO_GALLERY_ENABLED: 'specs.bookings.OwnerGallery',
};

export const PETRI_BASE_URL = 'https://www.wix.com';
export const PETRI_SCOPE = 'bookings-viewer-script';
export const DAILY_TIMETABLE_WIDGET_CONTROLLER_ID = DAILY_TIMETABLE_WIDGET_ID;
export const BOOK_BUTTON_WIDGET_CONTROLLER_ID = BOOK_BUTTON_WIDGET_ID;
export const STAFF_LIST_WIDGET_CONTROLLER_ID = STAFF_LIST_WIDGET_ID;
